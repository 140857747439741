import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/Nav"
import PortfolioNav from "../components/Portfolio"

const PortfolioLayout = styled.div`
  display: flex;
  margin-top: 5rem;
`

const CMSHTML = styled.div`

p {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}  

img {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}
`


const PortfolioPage = ({ data }) => {
  const { html, frontmatter } = data.allMarkdownRemark.edges[0].node
  const { title } = frontmatter
  return (
    <Layout>
      <SEO title="Samples" />
      <Nav page="portfolio" />
      <PortfolioLayout>
        <PortfolioNav>
        </PortfolioNav>
        <div style={{ width: '80%'}}>
          <div style={{ marginBottom: `.5rem` }}>
            <h2
              style={{
                maxWidth: '400px',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '2rem'
             }}
              >
              {title}
             </h2>
            </div>
           <CMSHTML
             style={{ marginBottom: `1rem` }}
             dangerouslySetInnerHTML={{ __html: html }}
           />
          </div>
        </PortfolioLayout>
      
    </Layout>
  )
}

export default PortfolioPage

export const PortfolioFeaturedProjectQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          whichCollection: { eq: "projects" }
          featured: { eq: true }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`
