import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const PortfolioLinkListItem = styled.li`
  border-radius: 3px;
  max-width: 146px;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;



  a {
    color: #000;
    text-decoration: none;
  }

  :hover {
    background-color: #1eaef1;

    a {
      color: #fff;
    }
  }
`

const PortfolioLink = ({ content }) => {
  const linkPath = `/portfolio/${content.node.parent.name}#top`
  return (
    <PortfolioLinkListItem>
      <Link to={linkPath}>
        <div>
          <div>
            <strong>{content.node.frontmatter.title}</strong>
          </div>
        </div>
      </Link>
    </PortfolioLinkListItem>
  )
}

export default PortfolioLink
