import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PortfolioLink from "./PortfolioLink"
import Toggle from "./Toggle"

const Portfolio = ({ children }) => {
  const data = useStaticQuery(graphql`
    query PORTFOLIO_LINK_LIST {
      allMarkdownRemark(
        filter: { frontmatter: { whichCollection: { eq: "projects" } } }
        sort: { fields: frontmatter___order }
      ) {
        edges {
          node {
            parent {
              ... on File {
                name
              }
            }
            frontmatter {
              title
              showMore
            }
          }
        }
      }
    }
  `)
  const portfolioLinkList = data.allMarkdownRemark.edges
  return (
    <section>
      {children && children}
      <ul style={{ display: `flex`, flexWrap: `wrap`, flexDirection: 'column', listStyleType: `none` }}>
        {portfolioLinkList.map((data, index) => {
          const showMore = data.node.frontmatter.showMore
          return (
            !showMore && (
              <PortfolioLink key={`portfolio_link_${index}`} content={data} />
            )
          )
        })}
      </ul>
      <Toggle>
        <div>
          <ul
            style={{ display: `flex`, flexWrap: `wrap`, flexDirection: 'column', listStyleType: `none` }}
          >
            {portfolioLinkList.map((data, index) => {
              const showMore = data.node.frontmatter.showMore
              return (
                showMore && (
                  <PortfolioLink
                    key={`portfolio_link_${index}`}
                    content={data}
                  />
                )
              )
            })}
          </ul>
        </div>
      </Toggle>
    </section>
  )
}

export default Portfolio
