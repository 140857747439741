import React, { useState } from "react"
import { useTransition, animated } from "react-spring"
import styled from "styled-components"
import LessIcon from "../assets/triangle-less.svg"
import MoreIcon from "../assets/triangle-more.svg"

const ToggleButtonStyles = styled(animated.button)`
  background-color: #fff;
  border: 2px solid #1eaef1;
  border-radius: 25px;
  color: #1eaef1;
  padding: 0.2rem 1rem;
  transition: background-color 0.2s, color 0.2s;
  margin-top: 1rem;

  svg polygon {
    transition: fill 0.2s;
  }

  :hover {
    background-color: #1eaef1;
    color: #fff;

    svg polygon {
      fill: #fff;
    }
  }
`

const Toggle = ({ children }) => {
  const [isToggled, setToggle] = useState(false)

  const fadeContent = useTransition(isToggled, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
  })

  return (
    <>
      {fadeContent.map(
        ({ item: toggle, key, props }) =>
          toggle && <animated.div style={props}>{children}</animated.div>
      )}
      {isToggled ? (
        <div style={{ marginTop: `1rem` }}>
          <ToggleButtonStyles
            onClick={() => {
              setToggle(!isToggled)
            }}
          >
            <div style={{ display: `flex` }}>
              <div
                style={{
                  marginRight: `.5rem`,
                  width: `.8rem`,
                }}
              >
                <LessIcon />
              </div>
              <div>Less</div>
            </div>
          </ToggleButtonStyles>
        </div>
      ) : (
        <div style={{ marginTop: `1rem` }}>
          <ToggleButtonStyles
            onClick={() => {
              setToggle(!isToggled)
            }}
          >
            <div style={{ display: `flex` }}>
              <div
                style={{
                  marginRight: `.5rem`,
                  width: `.8rem`,
                }}
              >
                <MoreIcon />
              </div>
              <div>More</div>
            </div>
          </ToggleButtonStyles>
        </div>
      )}
    </>
  )
}

export default Toggle
